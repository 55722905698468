<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import swal from 'sweetalert';

	export default {
		name: "WebSetting",
		extends: Base,
		data() {
			return {
				Name: "WebSetting",
				mrLevel: {},
				sosial_media: {},
				sosial_media_detail: {},
				sc_footer: {},
				cmp_name: {},
				cmp_address: {},
				cmp_number: {},
				cmp_email: {},
				marketing_wa: {},
				pagination: {},
				pagination_favorite: {},
				greeting_wa: {},
				username_instagram: {},
				client_id_instagram: {},
				client_secret_instagram: {},
				open_service: {},
				banner_img: {},
				banner_link: {},
				banner_text_en: {},
				banner_text_id: {},
				banner_status: {},
				btnPDFEN: {},
				btnPDFID: {}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			showMediaSosial(id) {
				$("#editSosmed").modal()
				this.sosial_media_detail = {}
				BOGen.apirest("/" + this.Name, {
					id: id,
					type: 'showMediaSosial'
				}, (err, resp) => {
					if (err) console.log(err)
					this.sosial_media_detail = resp.data
				}, "POST")
			},
			submitForm(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.sc_footer = this.sc_footer
				input.cmp_name = this.cmp_name
				input.cmp_address = this.cmp_address
				input.cmp_number = this.cmp_number
				input.cmp_email = this.cmp_email
				input.client_id_instagram = this.client_id_instagram
				input.client_secret_instagram = this.client_secret_instagram
				input.btnPDFID = this.btnPDFID
				input.btnPDFEN = this.btnPDFEN
				input.type = 'update'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info')
						this.refreshData()
					}
				}, "POST")
			},
			updateAccountSetting(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.marketing_wa = this.marketing_wa
				input.greeting_wa = this.greeting_wa
				input.username_instagram = this.username_instagram
				input.type = 'updateAccountSetting'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info2')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info2')
						this.refreshData()
					}
				}, "POST")
			},
			updatePagination(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.pagination = this.pagination
				input.type = 'updatePagination'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info-pagination')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info-pagination')
						this.refreshData()
					}
				}, "POST")
			},
			updatePaginationFavorite(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.pagination = this.pagination_favorite
				input.type = 'updatePaginationFavorite'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000,
						'.info-pagination-favorite')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info-pagination-favorite')
						this.refreshData()
					}
				}, "POST")
			},
			editSosialMedai(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input = this.sosial_media_detail
				input.type = 'editSosialMedai'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					$("#editSosmed").modal("hide")
					if (resp.success) swal(resp.message, '', 'success')
					this.refreshData()
				}, "POST")
			},
			updateBannerSetting(e, callback) {
				if (this.pagination_favorite.aws_val < 3) return swal('Minimal Amount Of Pagination Is 3', '', 'warning')
				if (e && e.btnLoading()) return;
				let input = {}
				input.banner_img = this.banner_img
				input.banner_text_en = this.banner_text_en
				input.banner_text_id = this.banner_text_id
				input.banner_link = this.banner_link
				input.banner_status = this.banner_status
				input.pagination_favorite = this.pagination_favorite
				input.type = 'updateBanner'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					Gen.info(resp.message, resp.style, 3000, '.info-banner')
					this.refreshData()
				}, "POST")
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">User Dashboard Setting</h5>
						</div>
						<hr>
						<VForm @resp="updateBannerSetting">
							<div class="row">
								<div class="col-md-12 info-banner"></div>
								<div class="col-md-5">
									<BoField :label="'Description EN'">
										<CKEditor name="banner_text_en" v-model="banner_text_en.aws_val"></CKEditor>
									</BoField>
									<BoField :label="'Link'"
										:attr="{type:'link',placeholder:'https://www.dimatique.com/...'}"
										v-model="banner_link.aws_val"></BoField>
									<BoField name="status" :label="'Status'">
										<div class="row">
											<div class="col-6">
												<div class="row">
													<radio name="status" v-model="banner_status.aws_val" option="Y"
														:attr="validation('bu_is_active')">Active</radio>
													<radio name="status" v-model="banner_status.aws_val" option="N">
														Inactive</radio>
												</div>
											</div>
										</div>
									</BoField>
								</div>
								<div class="col-md-5">
									<BoField :label="'Description ID'">
										<CKEditor name="banner_text_id" v-model="banner_text_id.aws_val"></CKEditor>
									</BoField>
									<div class="col-md-7">
										<!-- <BoField :label="'Pagination Favourite Dashboard'">
											<InputCurrency class="form-control" v-model="pagination_favorite.aws_val"></InputCurrency>
										</BoField> -->
										<!-- <BoField :label="'Pagination Favourite Dashboard'" :attr="{type:'Number'}" v-model="pagination_favorite.aws_val" min="4"></BoField> -->
										<label class="control-label">Pagination Favourite Dashboard</label>
										<input type="number" v-model="pagination_favorite.aws_val" min="3"
											class="form-control">
									</div>
								</div>
								<div class="col-md-2">
									<BoField name="banner_img" :label="'Images Banner'">
										<Uploader isDelete name="banner_img" type="banner" uploadType="cropping"
											:param="{thumbnail:true}" v-model="banner_img.aws_val"></Uploader>
									</BoField>
								</div>
								<div class="col-12 text-right">
									<button type="submit" class="btn btn-info btn-loading btn-rounded">Save Changes</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">WhatsApp Setting</h5>
						</div>
						<hr>
						<VForm @resp="updateAccountSetting">
							<div class="row">
								<div class="col-md-12 info2"></div>
								<div class="col-md-6">
									<BoField :label="'Greetings Whatsapp'">
										<textarea class="form-control" v-model="greeting_wa.aws_val" raw="3"></textarea>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="aws_val" :label="'Phone Number'" :attr="{type:'Number'}">
										<div class="input-group mb-n4">
											<div class="input-group-prepend">
												<span class="input-group-text">+62</span>
											</div>
											<input type="text" onkeydown="return mobileKey(event)" class="form-control"
												v-bind="validation('aws_val')" v-model="marketing_wa.aws_val"
												placeholder='0856xxxxxxxx'>
										</div>
									</BoField>
								</div>
								<div class="col-12 text-right">
									<button type="submit" class="btn btn-info btn-loading btn-rounded">Save Changes</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-12">
				<VForm @resp="submitForm" action="post">
					<div class="card">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">Footer</h5>
							</div>
							<hr>
							<div class="row">
								<div class="col-md-12 info"></div>
								<div class="col-md-6">
									<label for="">Footer Logo</label>
									<div class="row">
										<div class="col-12">
											<Uploader isBG class="prev-red" name="asc_img1" type="img_footer"
												uploadType="cropping" :param="{thumbnail:true}"
												v-model="sc_footer.asc_img1"></Uploader>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<BoField :label="'Company Name'" v-model="cmp_name.aws_val"></BoField>
										<!-- <BoField :label="'Company Email'" v-model="cmp_email.aws_val"></BoField>
										<BoField name="aws_val" :label="'Phone Number'" :attr="{type:'Number'}">
											<div class="input-group mb-n4">
												<div class="input-group-prepend">
													<span class="input-group-text">+62</span>
												</div>
												<input type="number" class="form-control" v-model="cmp_number.aws_val" placeholder='0856xxxxxxxx'>
											</div>
										</BoField> -->
									</div>
									<div class="form-group">
										<BoField name="sc_desc_en" :label="'Company Detail'">
											<textarea name="sc_desc_en" v-model="cmp_address.aws_val" rows="4"
												class="form-control"></textarea>
										</BoField>
									</div>

								</div>
								<!--
								<div class="col-md-6 col-sm-12 mt-3">
									<div class="form-group">
										<BoField name="asc_desc_en">
											<CKEditor name="content4" type="simple" v-model="sc_footer.asc_desc_en">
											</CKEditor>
										</BoField>
									</div>
									
										<BoField :label="'Instagram Client ID'" v-model="client_id_instagram.aws_val"></BoField>
								</div>
								<div class="col-md-6 col-sm-12 mt-3">
									<div class="form-group">
										<BoField name="asc_desc_id">
											<CKEditor name="content5" type="simple" v-model="sc_footer.asc_desc_id">
											</CKEditor>
										</BoField>
									</div>
									
										<BoField :label="'Instagram Client SECRET'" v-model="client_secret_instagram.aws_val"></BoField>
								</div>
								-->
								<div class="col-12">
									<div class="card">
										<div class="card-body">
											<div class="row">
												<div class="col-md-3" v-for="(v,k) in sosial_media" :key="k">
													<div class="card border">
														<div class="card-body">
															<div class="row">
																<div class="col-md-9">
																	<h5 class="card-title">{{v.mas_title}}</h5>
																	<span class="badge badge-success"
																		v-if="v.mas_status == 'Y'">Active</span>
																	<span class="badge badge-danger"
																		v-else>Inactive</span>
																</div>
																<div class="col-md-3">
																	<p class="mb-0 text-right">
																		<a href="javascript:;"
																			@click="showMediaSosial(v.mas_id)"
																			class="text-inverse icon_action"><i
																				class="ti-pencil"></i></a>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6 col-sm-12 mt-3">
										<BoField :label="'Button Download PDF ID'" v-model="btnPDFID.aws_val"></BoField>
								</div>
								<div class="col-md-6 col-sm-12 mt-3">
										<BoField :label="'Button Download PDF EN'" v-model="btnPDFEN.aws_val"></BoField>
								</div>
								<div class="col-12 text-right">
									<button type="submit" class="btn btn-loading btn-info btn-rounded">Save Changes</button>
								</div>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
		<div id="editSosmed" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Edit Social Media</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<VForm @resp="editSosialMedai" action="post">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<BoField v-model="sosial_media_detail.mas_val"
											:label="sosial_media_detail.mas_title+' Link'"></BoField>
									</div>
								</div>
								<div class="col-12">
									<Checkbox class="mt-4" v-model="sosial_media_detail.mas_status">Active</Checkbox>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-rounded btn-loading btn-info">Submit</button>
						</div>
					</VForm>
				</div>
			</div>
		</div>
	</div>
</template>